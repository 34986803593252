// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:       #c8202e;
$body-color:          #323232;

$rot:					        $brand-primary;

$body-bg:				      #dcdcdc;

$banner-bg:				    #f4f4f4;

$footer-bg:				    #535353;
$footer-text:			    #d4d2d2;

$textw:				      	#fff;

$white:               #fff;

$font-size-base:    	1.125rem;
$font-size-base-h:    1rem;

$font-family-sans-serif: 	'Roboto', sans-serif;
$font-weight-normal:		  300 !default;
$font-weight-bold:			  700 !default;

$headings-font-family:  	'Roboto', sans-serif;
$headings-font-weight:  	700 !default;
$headings-color:			    $body-color;

$h1-font-size:                $font-size-base-h * 1.75 !default;
$h2-font-size:                $font-size-base-h * 1.5 !default;
$h3-font-size:                $font-size-base-h * 1.25 !default;
$h4-font-size:                $font-size-base-h * 1.125 !default;
$h5-font-size:                $font-size-base-h !default;
$h6-font-size:                $font-size-base-h !default;

$link-color:			        $rot;
$link-hover-color:		    $rot;

$navbar-light-hover-color:    $rot !default;
$navbar-light-active-color:   $rot !default;

$dropdown-link-active-color:  $body-bg !default;

$dropdown-link-active-color:  $rot !default;
$dropdown-link-active-bg:     $white !default;

$h:						            20px;

$brandSize:				        140px;
$logoBGsize:			        $brandSize / 2;
$lineShadow:			        0px 5px 5px 0px rgba(0, 0, 0, 0.31);
$dropShadow:			        0px 5px 5px rgba(0, 0, 0, 0.31);
$smallShadow:			        0px 0px 5px 0px rgba(0, 0, 0, 0.41);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
) !default;

$fa-font-display: swap;
