/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/roboto-v30-latin-300.woff2") format("woff2"),
    url("../fonts/roboto-v30-latin-300.woff") format("woff");
  font-display: swap;
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    url("../fonts/roboto-v30-latin-regular.woff") format("woff");
  font-display: swap;
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/roboto-v30-latin-500.woff2") format("woff2"),
    url("../fonts/roboto-v30-latin-500.woff") format("woff");
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v30-latin-700.woff2") format("woff2"),
    url("../fonts/roboto-v30-latin-700.woff") format("woff");
  font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: FontAwesome;
}
