/* Global */
p {
    margin-bottom: 20px;
}

/*::-webkit-input-placeholder {
   color: $rot;
}
:-moz-placeholder {
   color: $rot;  
}
::-moz-placeholder {
   color: $rot;
}
:-ms-input-placeholder {  
   color: $rot;
}*/

:focus-visible {
    outline: 0;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

input,
textarea {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
    -webkit-appearance: none;
    border-radius: 0;
}

/* Body */
body {
    padding-top:43px;
}

.wrap .content-page {
    background-color: $white;
    box-shadow: -10px 10px 20px -15px rgba(0, 0, 0, 0.6), 10px 10px 20px -15px rgba(0, 0, 0, 0.6);
    overflow: auto;

    /* box-shadow:0px 15px 0px 0px white,0px -15px 0px 0px white, 5px 0px 8px #444,-5px 0px 8px #444;*/
    /* 	box-shadow: -0px 10px 0px -0px rgba(255,255,255,0.6), -0px -10px 0px -0px rgba(255,255,255,1), 10px 0px 20px -5px rgba(0,0,0,0.6),  -10px 0px 20px -5px rgba(0,0,0,0.6);	*/
}

.wrap .content-page {
    padding-top: 30px;

    &.content-start {
        padding-top: 15px;
    }
}

/* Header */

.banner {
    background-color: $banner-bg;
    box-shadow: $lineShadow;
    position: relative;
    z-index: 20;
}

.brand {
    display: block;
    background: url(../images/ifoer-logo-kreis.svg) 0px 0px no-repeat;
    width: $brandSize / 2.5;
    height: $brandSize / 2.5;
    margin: 00;
}

.logo {
    position: relative;
    display: inline-block;
    padding-top: 20px;
}

.logoBG {
    /*background: url(../images/kreis.svg) left bottom no-repeat;
	background-size: 150px 150px;
	position:absolute;
	width: 150px;
	height:55px;
	z-Index:-1;
	filter: drop-shadow( $dropShadow );
	bottom:5px;*/
}

.contact {
    margin: 15px 0;
    display: inline-block;
}

.topfixed {
    position: fixed;
    top: 0;
    z-index: 50000;
    width: 100%;
}

.tel {

    background: $rot;
    color: $white;

    a {
        color: $white;

        &:hover {
            text-decoration: none;
        }

        span {
            font-size: 10px;
            color: $rot;
            display: block;
            margin-top: -10px;
        }
    }
}

.standort {
    position: relative;

    >a {
        font-size: 16px;
        border: 1px solid $rot;
        padding: 4px 16px;
        display: block;
        
    }

    #standortList {
        position: absolute;
        margin-top: 10px;
        z-index: 5000;
    }

    #standortList {
        &::before {
            content: ' ';
            height: 0;
            position: absolute;
            width: 0;
            border: 10px solid transparent;
            /* arrow size */
            left: 8px;
        }

        &::before {
            border-bottom-color: $rot;
            top: -19px;
            z-index: 2;
        }

        li {
            white-space: nowrap;
            padding: 5px 10px;
        }

        .active a {
            color: $textw;
        }
    }
}

.plzsuche {
    background-color: rgba($body-bg, 0.2);
}

/* Nav */
.nav-primary a,
.nav-mobile a {
    color: $body-color;
    font-weight: 500;    
    text-decoration: none;

    &:hover,
    &:focus {
        color: $rot;
        text-decoration: none;
    }
}

.nav-primary,
.nav-mobile {
    .active > a {
        color: $rot;
    }
}

.nav-primary .active>a {
    position: relative;

    &:after,
    &:hover:after {
        content: '';
        position: absolute;
        background: $rot;
        width: 100%;
        max-width: calc(100% - 26px);
        height: 2px;
        margin: 0 13px;
        display: block;
        animation-name: slideInUp;
        animation-duration: 1s;
        animation-fill-mode: both;
    }
}

.nav-primary li:last-child a {
    padding-right: 0;
}


.navbar-toggler:focus {
    outline: 0px dotted;
}

.nav-mobile {
    display: block;
    width: 100%;
    clear: both;

    #navbarNav {
        width: 100%;
    }
}

.nav-desktop {
    display: none;
}

.headerTop {
    margin-top: 18px;
}

.navbar-toggler {
    display: inline-block;
}

.nav-desktop .nav .sub-menu {
    display: none;
    position: absolute;
    background: $banner-bg;
    padding: 15px;
}

.nav-desktop .nav>li:hover .sub-menu {
    display: block;
    list-style: none;
}

.nav-desktop .nav>li.menu-item-has-children {
    position: relative;
}

.standortnav.show ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;

        .dropdown-item {
            padding: 0.25rem;
            font-size: 1rem;
        }
    }
}

.nav-mobile {
    padding-left: 0;
    padding-right: 0;

    .navbar-nav > li.nav-item {
        padding: 0 1rem;
    }

    .dropdown {
        &.show {
            background: rgba(255,255,255,0.5);
        }

        .dropdown-toggle {
            position: relative;
            width: 100%;
            margin: 0;

            &::after {
                content: "\f107";
                font-family: FontAwesome;
                border: 0 !important;
                font-size: 1.5rem;
                position: absolute;
                right: 1rem;
                top: 0;
            }
        }
    }

    .dropdown-menu {
        background: transparent;
        border-radius: 0;
        border: 0;
        font-size: 1rem;
        margin: 0;
        padding-top: 0;

        .dropdown-item {
            padding: 0;
        }
    }
}

/* Footer */
footer {
    background: $footer-bg;
    color: $footer-text;
    font-size: 14px;
    padding: 30px 0 0;
}

footer a {
    color: $footer-text;
    padding: 0.35rem 0.25rem;
    margin-bottom: 0.125rem;
    display: inline-block;
}

footer .h4 {
    color: $footer-text;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.footernav,
.footernav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footernav>li {
    font-weight: $font-weight-bold;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.footernav .sub-menu>li {
    font-weight: $font-weight-normal;
    padding-bottom: 0;
    text-transform: none
}

.footernav a {
    &:hover {
        text-decoration: none;
    }
}

.footernav .active a {

    &:after,
    &:hover:after {
        background: none;
        height: 0;
        margin: 0;
    }
}

.minikarte img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");

    -webkit-filter: grayscale(99%);
    /* Chrome 19+ & Safari 6+ */
    -webkit-backface-visibility: hidden;
}

.copy {
    padding: 20px 0 10px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.slide .carousel-item img {
    width: 100%;
}

.img-fill {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.h-250 {
    height: 250px;
}


/* Animation */
.slideInUp {
    animation-name: slideInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes slideInUp {
    0% {
        transform: translateY($h);
        visibility: visible;
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Content */
.content-page {
    /*min-height:600px;*/
}

.sec {
    padding-bottom: 45px;
    margin-bottom: 45px;
    position: relative;
}

.sec:after {
    position: absolute;
    height: 2px;
    width: 60%;
    content: '';
    background: $rot;
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 430px;
}

.sec h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 30px;
}

.sec .bild img {
    width: 100%;
    height: auto;
}

.sec .bildTeaserHelper {
    background-clip: content-box;
    box-shadow: $smallShadow;
    background: $banner-bg;
    margin: 0 15px 30px;
    padding: 0
}

/*.sec .bildTeaser{ margin-bottom:30px; }*/
.sec .bildTeaser .bild {
    position: relative;
    overflow: hidden;
}

/*.sec .bildTeaser h3{ transform: rotateZ(-20deg)   skewX(-20deg); background: $rot; box-shadow: 6px 8px 10px 1px rgba(0, 0, 0, 0.75); color:$textw; font-size:24px; font-weight: $headings-font-weight; padding:15px 35px 15px 70px; text-transform:uppercase; display:inline-block; position:absolute; top:0; transform-origin: 100% 0 0; left:-63px; top:2px}*/

.sec .bildTeaser a h3 {
    transition: all 0.5s ease;
}

.sec .bildTeaser h3 {
    background: #c8202e;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 15px 35px 15px 35px;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    transform-origin: bottom left;
    left: 0;
    bottom: 0;
    width: 100%;
    right: 0;
    transition: all 0.5s ease;
}

.secBildTeaser a:hover h3 {
    transform: rotate(0deg) skewX(-0deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding-top: 70px;
}

.secBildTeaser a:hover {
    text-decoration: none;
}

.sec .bildTeaser .text {
    /* padding: 10px 120px 5px 35px; */
    padding: 10px; 
    line-height: 24px;
}

.sec .bildTeaser .teaserLogo {
    display: none
}

.sec .text ul {
    padding: 20px 30px;
}

.sec .textTeaser {
    margin-bottom: 30px;
}

.sec .textTeaser h3 {
    font-size: 20px;
    margin-bottom: 30px
}

.sec .textTeaser .teaserLogo {
    
    width: 50px;
    height: 50px;
    background: url(../images/ifoer-logo-kreis.svg) 0 0 no-repeat;
    background-size: 50px 50px;
    margin: 0 auto 15px;
    display: block;
}

.sec .textTeaser .teaserContent {
    display: block; 
    text-align: justify;   
}

.sec.secText.text-center ul {
    text-align: left;
}

.secText {
    .text {
        p:last-child {
            margin-bottom: 0;
        }
    }
}

.secHinweis {
    margin-bottom: 0;
    padding: 20px 0 0;
}

.secHinweis:after {
    background: transparent;
    height: 0;
}

.secHinweis .text {
    overflow: hidden;
}

.vbHinweis {
    background: $rot;
    color: $textw;

    h2 {
        color: $textw;
        margin: 0 0 10px;
    }
    
    a {
    color: $textw;

        &:hover {
            color: $textw;
        }
    }

    .btn-ifoer {
        padding: 15px 30px;
        border: 3px solid $textw;
        background: $rot;
        color: $textw;
        cursor: pointer;
    }
}

.ibHinweis {
    background: $rot;
    color: $textw;

    h2 {
        color: $textw;
        margin: 0 0 10px;
    }

    /*margin-bottom:90px;*/

    a {
        color: $textw;

        &:hover {
            color: $textw;
        }
    }

    .btn-ifoer {
        padding: 15px 30px;
        border: 3px solid $textw;
        background: $rot;
        color: $textw;
        cursor: pointer;
    }
}


.paddingButton {
    padding-top: 18px;
}

.closing {
    height: 120px;
}

.randlos {
    margin-left: -15px;
    margin-right: -15px;
}

#map {
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
}

.adresse {
    margin-bottom: 30px;
}

.kontaktTel label {
    width: 30px;
    color: $rot;
}


.secFormular input {
    outline: none;
    //color: $kontaktBraun; 
}

.secFormular input[type="text"],
.secFormular input[type="email"],
.secFormular input[type="tel"] {
    border: 1px solid rgba($body-color, 0.15);
    box-shadow: none;
    background: none;
}

.secFormular input[type="submit"],
.plzsuche button {
    margin-top: 30px;
    padding: 20px 30px;
    background: none;
    box-shadow: none;
    border: 1px solid $footer-bg;
    text-transform: uppercase;
    cursor: pointer;
    color: $footer-bg;
    width: 100%;
    appearance: none;
}

.secFormular textarea {
    padding: 14px;
    border: solid 1px $footer-bg;
    background: none;
    outline: none;
    width: 100%;

    resize: none;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.secFormular .cInput {
    border-bottom: 1px solid $footer-bg;
    margin: 15px 0 20px;
    padding: 0;

    &:last-child {
        border-bottom: none;
    }
}

.wpcf7-acceptance,
.wpcf7-checkbox {



    input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        padding: 0;
        margin-right: 15px;
        width: 20px;
        top: 6px;
    }

    input[type="checkbox"]:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        margin-top: -2px;
        width: 20px;
        height: 20px;
        background: #fcfcfc;
        border: 1px solid #aaa;
        border-radius: 2px;
    }


    input[type="checkbox"]:checked:before {
        background: #000;
        border-color: #000;
    }

    input[type="checkbox"]:disabled {
        color: #b8b8b8;
        cursor: auto;
    }

    input[type="checkbox"]:disabled:before {
        box-shadow: none;
        background: #ddd;
    }

    input[type="checkbox"]:checked:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 8px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
    }


    span.wpcf7-list-item {
        margin: 20px 0 0 0;
    }

}

.checkbox-style input[type="checkbox"] {
    visibility: hidden;
}

.checkbox-style .checked {
    opacity: 0;
    transition: all 0.2s ease 0s;
}

.checkbox-style .unchecked,
.checkbox-style .checked {
    border: solid 1px $footer-bg;
    display: block;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0px;
    right: 15px;
    cursor: pointer;
}

.checkbox-style .checked:after {
    position: absolute;
    content: '';
    top: -6px;
    left: 9px;
    width: 12px;
    height: 26px;
    transform: rotate(45deg);
    border: solid $rot;
    border-width: 0 3px 3px 0;

}

.checkbox-style.checked .checked {

    opacity: 1;

    transition: all 0.2s ease 0s;
}

.wpcf7 :not(#anrufen) label{
    width: calc(100% - 35px);
    vertical-align: text-top;
}   

.btn_farbe {
    border-radius: 0;
    cursor: pointer;
    color: white;
}

.farbe_grun {
    background: #5bcf54;
}

.farbe_orange {
    background: #fa4e10;
}

.farbe_rot {
    background: $rot;
}

@include media-breakpoint-down(xs) { 
    .btn-xs-full {
        width: 100%;
        display: block;
    }
 }

@include media-breakpoint-up(xs) {}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {

    .wrap .content-page {
        padding: 0 100px;
    }

    .secHinweis {
        padding: 20px 85px 0;
    }

    .ibHinweis {
        margin-left: -100px;
        margin-right: -100px;
        /*margin-bottom: 90px;*/
    }

    .banner {
        height: 110px;
    }

    .container-max {
        max-width: map-get($container-max-widths, xl);
    }

    .randlos {
        margin-left: -115px;
        margin-right: -115px;
    }

    .nav-primary {
        a {
            color: $body-color;
            font-weight: 500;
            text-align: center;
            padding: 10px 14px;
            text-decoration: none;
            font-size: 16px;

            &:hover,
            &:focus {
                color: $rot;
                text-decoration: none;
            }

        }

        .sub-menu {
            padding: 15px 0 0;

            a {
                text-align: left !important;
                display: block;
                padding: 5px 2px;
                white-space: nowrap;
            }
        }
    }

    

    .navbar {
        padding: 0;
    }

    .brand {
        display: block;
        background: url(../images/ifoer-logo-kreis.svg) 0px 0px no-repeat;
        width: $brandSize;
        height: $brandSize;
        margin: 0 10px 0 5px;
    }

    .logoBG {
        background: url(../images/kreis.svg) left bottom no-repeat;
        background-size: 150px 150px;
        position: absolute;
        width: 150px;
        height: 55px;
        z-Index: -1;
        filter: drop-shadow($dropShadow);
        bottom: 5px;
    }

    .h-md-500 {
        height: 500px;
    }

    .secFormular input[type=submit],
    .plzsuche button {
        width: auto;
    }

    .headerTop {
        width: auto;
        margin-top: 0;
    }

    .standort>a {
        margin-right: 40px;
    }

    .sec .text ul {
        padding: 20px 120px;
    }

    .nav-mobile {
        display: none
    }

    .nav-desktop {
        display: block;
    }

    .bildTeaserHelper {
        max-width: calc(50% - 30px) !important;
    }

    .sec .bildTeaser .text {
        padding: 10px 120px 5px 35px;        
    }
    
    .sec .bildTeaser .teaserLogo {
        width: 100px;
        height: 100px;
        background: url(../images/ifoer-logo-kreis.svg) 0 0 no-repeat;
        background-size: 100px 100px;
        position: absolute;
        right: 25px;
        bottom: 40px;
    }

}

@include media-breakpoint-up(xl) {
    .wrap .content-page {
        padding: 0 150px;
    }

    .secHinweis {
        padding: 20px 135px 0;
    }

    .ibHinweis {
        margin-left: -150px;
        margin-right: -150px;
        /*margin-bottom: 90px;*/
    }

    .randlos {
        margin-left: -165px;
        margin-right: -165px;
    }

}